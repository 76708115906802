<template>
  <div class="inner-section application-form-wrapper">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenPanel.bidder_application') + ' ' + ($route.query.id ? $t('globalTrans.update') : $t('globalTrans.apply')) }}</h4>
      </template>
      <template v-slot:body>
          <b-overlay :show="isLoading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
                <!-- input-form -->
                <InputForm :application="application" :isRenew="true" :licenseSearch="false"/>
                <!-- attachment-form -->
                <AttachmentForm :application="application" :isRenew="true"/>
                <!-- submit buttons -->
                <b-row class="text-right mb-3">
                <b-col>
                    <b-button type="button" @click="saveUpdate(1)" variant="warning" class="mr-2 btn-sm">{{ $t('globalTrans.draft') }}</b-button>
                    <b-button type="button" @click="saveUpdate(2)" variant="success" class="mr-2 btn-sm">{{ $t('teaGardenBtriService.submit_n_payment') }}</b-button>
                    <b-button variant="danger" class="btn-sm" @click="back()">{{ $t('globalTrans.cancel') }}</b-button>
                </b-col>
                </b-row>
            </b-form>
            </ValidationObserver>
          </b-overlay>
      </template>
    </body-card>
  </div>
</template>
<script>
// import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import BreadCumb from '@/components/BreadCumb.vue'
import InputForm from '../license-components/InputForm.vue'
import AttachmentForm from '../license-components/AttachmentForm.vue'
import { mapGetters } from 'vuex'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { biddersApplicationStoreApi, biddersApplicationUpdateApi } from '../../api/routes'
export default {
  components: { BreadCumb, InputForm, AttachmentForm },
  name: 'Form',
  data () {
    return {
        baseUrl: teaGardenServiceBaseUrl,
        valid: null,
        saveBtnName: this.$t('globalTrans.update'),
        errors: [],
        application: {
            application_type: 2,
            service_id: 32,
            garden_id: 0,
            parent_id: null,
            area_type_id: 0,
            division_id: 0,
            district_id: 0,
            city_corporation_id: 0,
            pauroshoba_id: 0,
            upazila_id: 0,
            c_area_type_id: 0,
            c_division_id: 0,
            c_district_id: 0,
            c_city_corporation_id: 0,
            c_pauroshoba_id: 0,
            c_upazila_id: 0,
            w_area_type_id: 0,
            w_division_id: 0,
            w_district_id: 0,
            w_city_corporation_id: 0,
            w_pauroshoba_id: 0,
            w_upazila_id: 0,
            business_type_id: 0,
            w_union_id: 0,
            union_id: 0,
            c_union_id: 0,
            trade_license_fiscal_year_id: 0
        },
        application_type: 1,
        draft: 1,
        isLoading: false
    }
  },
  created () {
     if (this.$route.query.id && this.$route.params.parent_id) {
        this.isLoading = true
        this.getData(this.$route.query.id)
        this.isLoading = false
    } else if (this.$route.params.parent_id) {
        this.isLoading = true
        this.getData(this.$route.params.parent_id)
        this.isLoading = false
    } else {
        this.isLoading = false
    }
  },
  computed: {
    loading: function () {
        return this.$store.state.commonObj.loading
    },
    ...mapGetters({
        commonProfile: 'Auth/commonProfile',
        authUser: 'Auth/authUser'
    }),
    id () {
        return this.$route.query.id
    }
  },
  methods: {
    back () {
        return this.$router.go(-1)
    },
    renewFileRequired () {
      this.application.old_license_attachment = ''
      this.application.company_memorandum = ''
      this.application.certificate_of_incor = ''
      this.application.agreement_attachment = ''
      this.application.farm_reg_certificate = ''
      this.application.necessary_documents = ''
      this.application.trade_license = ''
      this.application.income_tax_certificate = ''
      this.application.vat_certificate = ''
      this.application.director_biodata = ''
      this.application.bank_solvency_certificate = ''
      this.application.retail_wholesale_license = ''
    },
    async getData (id) {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(id))
        const tmp = JSON.parse(JSON.stringify(tmpData))
        this.application = Object.assign({}, tmp, { application_type: 2, parent_id: null, service_id: tmp.application.service_id })

        if (this.$route.params.parent_id && !this.$route.query.id) {
           this.application.old_license_expire_date = this.application.application.expired_date
           this.renewFileRequired()
        }
    },
    async saveUpdate (status = 1) {
        var check = await this.$refs.form.validate()
        this.application.status = status
        if (this.$route.params.parent_id && !this.$route.query.id) {
           this.application.parent_id = parseInt(this.$route.params.parent_id)
        }
        if (check) {
            let result = ''
            this.isLoading = true

            if (this.id) {
                result = await RestApi.putData(teaGardenServiceBaseUrl, `${biddersApplicationUpdateApi}/${this.id}`, this.application)
            } else {
                result = await RestApi.postData(teaGardenServiceBaseUrl, biddersApplicationStoreApi, this.application)
            }

            if (result.success) {
                this.$toast.success({
                title: 'Success',
                message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

            if (result.is_payment) {
                    const baseTableId = result.base_table_id
                    this.$router.push({ name: 'tea_garden_service.btb.payment', params: { id: baseTableId }, query: { from: 'tea_garden_service.btb.bidder_license_application' } })
                } else {
                    this.$router.push({ name: 'tea_garden_service.btb.bidder_license_application' })
                }
            } else {
                this.$refs.form.setErrors(result.errors)
                this.$toast.error({
                title: this.$t('globalTrans.error'),
                message: this.$t('globalTrans.form_error_msg'),
                color: '#ee5253'
                })
            }
            this.isLoading = false
        }
    }
  }
}
</script>
